import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from 'components/layouts/Layout';

function RefundAndCancellationPolicy() {
  const data = useStaticQuery(
    graphql`
      query {
        login_bg: file(relativePath: { eq: "bg-hero-darkened.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );

  const bg_login = getImage(data.login_bg);
  const loginBg = convertToBgImage(bg_login);

  return (
    <Layout pageTitle="Refund and Cancellation Policy">
      <BackgroundImage Tag="section" className="bg-bottom-footer" {...loginBg}>
        <section className="section-secondary relative w-full h-full">
          <div className="max-w-screen-2xl mx-auto grid grid-cols-12 pt-10 xl:px-20 px-5">
            <div className="col-span-12 pt-10 pb-5 xl:mb-20 mb-10 xl:mx-5 mt-5 xl:px-10 px-5 bg-white rounded-xl text-grey">
              <div className="secondary-page-content">
                <h1 className="text-left font-bold font-rokkit xl:mb-10 mb-6 xl:text-4xl text-3xl text-light-grey">
                  REFUND AND CANCELLATION POLICY
                </h1>
                <p className="font-opensans xl:mb-10 mb-5 xl:text-left text-left">
                  Our focus is complete customer satisfaction. In the event, if you are displeased with the services provided, we will
                  refund back the money, provided the reasons are genuine and proved after investigation. Please read the fine prints of
                  each deal before buying it, it provides all the details about the services or the product you purchase.
                </p>
                <p className="font-opensans xl:mb-10 mb-5 xl:text-left text-left">
                  In case of dissatisfaction from our services, clients have the liberty to cancel their projects and request a refund from
                  us. Our Policy for the cancellation and refund will be as follows:
                </p>
                <h2 className="font-rokkit xl:text-3xl text-xl mb-5 xl:text-left text-left">CANCELLATION POLICY</h2>
                <p className="font-opensans mb-5 xl:text-left text-left">For Cancellations please contact the us via contact us link.</p>
                <p className="font-opensans xl:mb-10 mb-5 xl:text-left text-left">
                  Requests received later than 14 business days prior to the end of the current service period will be treated as
                  cancellation of services for the next service period.
                </p>
                <h2 className="font-rokkit xl:text-3xl text-xl mb-5 xl:text-left text-left">REFUND POLICY</h2>
                <p className="font-opensans mb-5 xl:text-left text-left">
                  We will try our best to create the suitable design concepts for our clients.
                </p>
                <p className="font-opensans mb-5 xl:text-left text-left">
                  In case any client is not completely satisfied with our products we can provide a refund.
                </p>
                <p className="font-opensans mb-5 xl:text-left text-left">
                  If paid by credit card, refunds will be issued to the original credit card provided at the time of purchase and in case of
                  payment gateway name payments refund will be made to the same account.
                </p>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>
    </Layout>
  );
}

export default RefundAndCancellationPolicy;
